<template>
  <div class="main-container">
    <span class="iconfont icon-wuneirongtubiaoicon"></span>
    <div class="title">
      <img src="../assets/images/404.png" alt="" />
      <!-- <h1>404</h1> -->
      <h3>Something's missing</h3>
      <div class="desc">
        We can't find the page you're looking for. We will return to the home
        page.....{{ num }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 5,
      time: 'a'
    }
  },
  created() {
    this.time = setInterval(() => {
      this.num--
      if (this.num === 0) this.$router.push('/')
    }, 1000)
  },
  destroyed() {
    if (this.num === 0) clearInterval(this.time)
  }
}
</script>

<style lang="less" scoped>
.main-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  .iconfont {
    font-size: 200px;
    color: #747474;
  }
  .title {
    // width: 500px;
    margin-left: 10px;
    text-align: center;
    // h1 {
    //   font-size: 100px;
    // }
    img {
      width: 800px;
    }
    h3 {
      font-size: 40px;
      color: #727d99;
      padding-top: 20px;
    }
    .desc {
      font-size: 20px;
      color: #727d99;
    }
  }
}
</style>
